<template>
	<div>
		<div class="">
			<div class="card">
				<div class="card-body p-0">
					<v-server-table ref="creditSystemUsageTable" :columns="columns" :options="options"
						@loading="tableLoading = true" @loaded="tableLoading = false">
						<div class="d-flex-inline flex-row px-3 mt-4" slot="beforeLimit">
							<date-range-picker ref="dateRangePicker" :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }"
								:maxDate="new Date()" :singleDatePicker="false" :timePicker="true" :showDropdowns="true" :autoApply="true"
								@update="filterByDateRange" v-model="dateRange">
								<template v-slot:input="picker">
									<p style="min-width: 350px">
										{{ picker.startDate | date }} - {{ picker.endDate | date }}
									</p>
								</template>
							</date-range-picker>
							<button class="btn bg-light mx-2" @click.prevent="exportReport">
								Export
							</button>
						</div>

						<span slot="id" slot-scope="props">
							<span>{{ props.index }}</span>
						</span>

						<div slot="staff" slot-scope="props">
							<div>
								<span>{{ props.row.user.fname }} {{ props.row.user.lname }}</span>
							</div>
							<div>
								<span class="text-muted">{{ props.row.user.email }}</span>
							</div>
						</div>
						<span slot="main_wallet" slot-scope="props">
							<span>{{ props.row.wallet.amount || 0 }}</span>
						</span>
						<span slot="credit_wallet" slot-scope="props">
							<span>{{ props.row.wallet.credit_amount || 0 }}</span>
						</span>
					</v-server-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
const moment = require('moment')
import { ref, defineProps, computed } from 'vue'
import { axiosInstance } from '@/plugins/axios'


const date = computed((value) => {
  if (!value) return ''
  const options = { year: 'numeric', month: 'long', day: 'numeric' }
  return Intl.DateTimeFormat('en-US', options).format(value)
})

const props = defineProps({
  companyId: {
    required: true,
    type: [Number, String]
  }
})

const creditSystemUsageTable = ref(null)


const dateRange = ref({
  startDate: moment().startOf('month').toDate(),
  endDate: moment().endOf('month').toDate()
})
const tableLoading = ref(false)
const companies = ref(null)
const columns = ref([
  'id',
  'staff',
  'wallet_history.title',
  'amount',
  'balance_before',
  'balance_after',
  'created_at'
])

const options = ref({
  filterable: false,
  dateColumns: ['created_at'],
  headings: {
    id: 'ID',
    staff: 'Staff',
    'wallet_history.title': 'Wallet History',
    amount: 'Amount',
    balance_before: 'Balance Before',
    balance_after: 'Balance After',
    created_at: 'Created At'
  },
  requestFunction: (data) => {
    return new Promise((resolve) => {
      if (!data.startDate && dateRange.value.startDate) {
        data.startDate = dateRange.value.startDate
      }

      if (!data.endDate && dateRange.value.endDate) {
        data.endDate = dateRange.value.endDate
      }

      fetchStaff(data)
        .then((d) => resolve(d))
        .catch(function (e) {
          // this.dispatch('error', e);

          resolve({ data: { data: [], count: 0 } })
        })
    })
  }
})

const exportReport = () => {
    let q = ''
    if (dateRange.value.startDate) {
      q = `startDate=${dateRange.value.startDate}`
    }

    if (dateRange.value.endDate) {
      q = `${q}&endDate=${dateRange.value.endDate}`
    }
    // credit-line-usages/expor
    const url = `${process.env.VUE_APP_API_URL}/v1/corporates/${props.companyId}/credit-line-usages/export?${q}`
    const a = document.createElement('a')
    a.href = url
    a.target = '_blank'
    const body = document.getElementsByTagName('body')
    body[0].appendChild(a)
    a.click()
  }

 const filterByDateRange = ({ startDate, endDate }) => {
    creditSystemUsageTable.value.setCustomFilters({ startDate, endDate })
  }

 const fetchStaff = async (params) => {
    const result = await axiosInstance.get(
      `/v1/corporates/${props.companyId}/credit-line-usages`,
      { params }
    )
    const newResult = {
      ...result.data,
      data: result.data.data,
      count: result.data.total
    }
    return {
      data: newResult
    }
  }

</script>

<style lang="scss" scoped>
$primary: #01e069 !default;
$black: #060606 !default;

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: transparent;
  box-shadow: 0 3px 0 0px $primary;
  color: $black;
  font-weight: 800;
}

.nav-pills .nav-link,
.nav-pills .show>.nav-link {
  border-radius: 0;
}

.nav-link.active {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
